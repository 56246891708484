import {
  createEmailTemplate,
  deleteEmailTemplate,
  fetchEmailTemplates,
  updateEmailTemplate,
} from "@packages/api";
import { useAuthToken } from "@packages/auth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useError } from "contexts/AlertContext";

export function useListEmailTemplates() {
  const { token } = useAuthToken();
  const { error } = useError();
  return useQuery<any, undefined>(
    ["email-template"],
    async () => fetchEmailTemplates(token),
    { onError: error },
  );
}

export function useCreateEmailTemplate() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (template: any) => createEmailTemplate(token, template),
    {
      onSuccess: (template) => {
        queryClient.setQueryData(
          ["email-template"],
          (cache: any[] | undefined) =>
            Array.isArray(cache) ? [...cache, template] : [template],
        );
      },
      onError: error,
    },
  );
}

export function useUpdateEmailTemplate(templateId: string) {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (template: any) => updateEmailTemplate(token, templateId, template),
    {
      onSuccess: (template) =>
        queryClient.setQueryData(
          ["email-template"],
          (cache: any[] | undefined) =>
            Array.isArray(cache)
              ? [...cache.filter(({ id }) => id !== template.id), template]
              : [template],
        ),
      onError: error,
    },
  );
}

export function useDeleteEmailTemplate() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(async (id: string) => deleteEmailTemplate(token, id), {
    onSuccess: (templateId) =>
      queryClient.setQueryData(
        ["email-template"],
        (cache: any[] | undefined) =>
          Array.isArray(cache)
            ? cache.filter(({ id }) => id !== templateId)
            : cache,
      ),
    onError: error,
  });
}
