import { Control, useController } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { useSMSTemplates } from "api/hooks/sms";

import { useListEmailTemplates } from "../../../api/hooks/email";

const SelectElement = ({
  control,
  useFetchHook,
  fieldName = "templateID",
}: {
  control: Control<any, any>;
  useFetchHook: () => { data: any };
  fieldName?: string;
}) => {
  const { data: templates } = useFetchHook();
  const {
    field: { onChange, value, ref },
  } = useController({
    control,
    name: fieldName,
    defaultValue: "",
  });

  return (
    <>
      <FormLabel>Message Template</FormLabel>
      <Select
        value={value}
        ref={ref}
        aria-label="template select"
        onChange={(e) => onChange(e.target.value, fieldName)}
      >
        {templates?.map((template, key) => (
          <option
            key={key}
            value={template.id}
            aria-label={`${template.name} option`}
          >
            {template.name}
          </option>
        ))}
      </Select>
      <FormHelperText>
        The template for this text message (optional).
      </FormHelperText>
    </>
  );
};

export const TemplateSelect =
  ({ templateType }: { templateType: "sms" | "email" }) =>
  ({
    control,
    fieldName = "templateID",
  }: {
    control: Control<any, any>;
    fieldName?: string;
  }) => {
    return (
      <SelectElement
        control={control}
        useFetchHook={
          templateType === "sms" ? useSMSTemplates : useListEmailTemplates
        }
        fieldName={fieldName}
      />
    );
  };

export const FormTemplateSelect = ({
  register,
  trigger,
  getValues,
  control,
}: {
  register: any;
  trigger: any;
  getValues: any;
  control: Control<any, any>;
}) => {
  const SelectElement = TemplateSelect({
    templateType: getValues("communication"),
  });
  return (
    <>
      <FormControl mt="2">
        <HStack spacing="2">
          <FormLabel mb="0">Communication</FormLabel>
          <Tooltip placement="top" label={"Communication"}>
            <div>
              <Select
                {...register("communication", {
                  required: {
                    value: true,
                    message: "Communication is required.",
                  },
                  onChange: () => trigger("communication"),
                })}
              >
                <option value="sms">SMS</option>
                <option value="email">Email</option>
              </Select>
            </div>
          </Tooltip>
        </HStack>
      </FormControl>
      <FormControl>
        <SelectElement control={control} />
      </FormControl>
    </>
  );
};
