import { ISMSTemplateOut } from "@packages/types";

import { apiClient, withAuth } from "../client";

export const fetchEmailTemplates = async (token: string) => {
  const { data } = await apiClient.get("/email/templates", withAuth(token));
  return data;
};

export const createEmailTemplate = async (
  token: string,
  template: ISMSTemplateOut,
) => {
  const { data } = await apiClient.post(
    "/email/templates",
    template,
    withAuth(token),
  );
  return data;
};
export const updateEmailTemplate = async (
  token: string,
  id: string,
  template: ISMSTemplateOut,
) => {
  const { data } = await apiClient.put(
    `/email/templates/${id}`,
    template,
    withAuth(token),
  );
  return data;
};

export const deleteEmailTemplate = async (token: string, id: string) => {
  await apiClient.delete(`/email/templates/${id}`, withAuth(token));
  return id;
};
