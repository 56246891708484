export enum SMSStatusFlag {
  Unknown,
  Queued,
  Sent,
  Delivered,
  Invalid,
}

export interface IDeliveryStatus {
  mobile: string;
  sid: string;
  queueTS: Date;
  sentTS?: Date;
  deliveredTS?: Date;
}

export interface IPhoneDeliveryStatus {
  [phone: string]: IDeliveryStatus[];
}

export interface IResponse {
  slides: {
    [id: string]: any;
  };
  createdAt: Date;
}

export interface IPatientData {
  colour?: string | undefined;
  counter?: object;
}

export interface IPatient {
  id: string;
  patientEmail: string;
  data?: IPatientData;
  status?: SMSStatusFlag;
  appointmentTime?: Date;
  phone: string;
  tenant: string;
  form: string;
  doctor: string;
  response?: IResponse[];
  alertTargets: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IAlertTarget {
  email: string;
  phone: string;
}

export interface IPatientCreate {
  email: string;
  templateID: string;
  appointmentTime: Date;
  phone: string;
  tenant: string;
  form: string;
  alertTargets: IAlertTarget[];
  communication: "sms" | "email";
}

export const emptyPatient = (form?: string): Partial<IPatientCreate> => {
  return {
    phone: undefined,
    form,
    templateID: "",
    appointmentTime: undefined,
    alertTargets: [],
    email: "",
    communication: "sms",
  };
};

export interface IPatientOut {
  appointment_time: Date;
  phone: string;
  patient_email: string;
  tenant: string;
  form: string;
  alert_targets: IAlertTarget[];
  template_id: string;
}

export interface IDoctorPatients {
  [doctor: string]: IPatient[];
}
