import { useAuthToken } from "@packages/auth";
import {
  IPhoneDeliveryStatus,
  IScheduledSMS,
  IScheduledSMSOut,
  ISMSTemplate,
  ISMSTemplateOut,
} from "@packages/types";

import { apiClient, withAuth } from "../client";
import {
  serialiseArrayResponse,
  serialiseDeliveryStatus,
  serialiseSMS,
  serialiseSMSTemplate,
  toPhoneDeliveryStatus,
} from "../utils/serialisation";

export const fetchSMSTemplates = async (
  token: string,
): Promise<ISMSTemplate[]> => {
  const { data } = await apiClient.get("/sms/templates", withAuth(token));
  return serialiseArrayResponse(data, serialiseSMSTemplate);
};

export const createSMSTemplate = async (
  token: string,
  template: ISMSTemplateOut,
): Promise<ISMSTemplate> => {
  const { data } = await apiClient.post(
    "/sms/templates",
    template,
    withAuth(token),
  );
  return serialiseSMSTemplate(data);
};

export const updateSMSTemplate = async (
  token: string,
  id: string,
  template: ISMSTemplateOut,
): Promise<ISMSTemplate> => {
  const { data } = await apiClient.put(
    `/sms/templates/${id}`,
    template,
    withAuth(token),
  );
  return serialiseSMSTemplate(data);
};

export const deleteSMSTemplate = async (token: string, id: string) => {
  await apiClient.delete(`/sms/templates/${id}`, withAuth(token));
  return id;
};

// Scheduled SMS'es
export const fetchScheduledSMSes = async (
  token: string,
): Promise<IScheduledSMS[]> => {
  const { data } = await apiClient.get("/sms/scheduled", withAuth(token));
  return serialiseArrayResponse<IScheduledSMS>(data, serialiseSMS);
};

export const createScheduledSMS = async (
  token: string,
  sms: IScheduledSMSOut,
): Promise<IScheduledSMS> => {
  const { data } = await apiClient.post("/sms/scheduled", sms, withAuth(token));
  return serialiseSMS(data);
};

export const updateScheduledSMS = async (
  token: string,
  id: string,
  sms: IScheduledSMSOut,
): Promise<IScheduledSMS> => {
  const { data } = await apiClient.put(
    `/sms/scheduled/${id}`,
    sms,
    withAuth(token),
  );
  return serialiseSMS(data);
};

export const deleteScheduledSMS = async (token: string, id: string) => {
  await apiClient.delete(`/sms/scheduled/${id}`, withAuth(token));
  return id;
};

export const deleteFormSMS = async (token: string, form: string) => {
  const smses = await fetchScheduledSMSes(token);
  smses.forEach((sms) => {
    if (sms.form === form) {
      apiClient.delete(`/sms/scheduled/${sms.id}`, withAuth(token));
    }
  });
  return form;
};

// Deliverability
export const fetchDeliverability = async (
  token: string,
  phones: string[],
): Promise<IPhoneDeliveryStatus> => {
  const { data } = await apiClient.post(
    "/sms/deliverability/list",
    phones,
    withAuth(token),
  );
  return toPhoneDeliveryStatus(
    serialiseArrayResponse(data, serialiseDeliveryStatus),
  );
};
