import {
  createScheduledSMS,
  createSMSTemplate,
  deleteFormSMS,
  deleteScheduledSMS,
  deleteSMSTemplate,
  fetchDeliverability,
  fetchScheduledSMSes,
  fetchSMSTemplates,
  updateScheduledSMS,
  updateSMSTemplate,
} from "@packages/api";
import { useAuthToken } from "@packages/auth";
import {
  IPatient,
  IPhoneDeliveryStatus,
  IScheduledSMS,
  IScheduledSMSOut,
  ISMSTemplate,
  ISMSTemplateOut,
} from "@packages/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useError } from "contexts/AlertContext";

// SMS template hooks
export function useSMSTemplates() {
  const { error } = useError();
  const { token } = useAuthToken();
  return useQuery<ISMSTemplate[], undefined>(
    ["smstemplates"],
    async () => fetchSMSTemplates(token),
    { onError: error },
  );
}

export function useCreateSMSTemplate() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (template: any) => createSMSTemplate(token, template),
    {
      onSuccess: (template) => {
        queryClient.setQueryData(
          ["smstemplates"],
          (cache: any[] | undefined) =>
            Array.isArray(cache) ? [...cache, template] : [template],
        );
      },
      onError: error,
    },
  );
}

export function useUpdateSMSTemplate(templateId: string) {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (template: any) => updateSMSTemplate(token, templateId, template),
    {
      onSuccess: (template) =>
        queryClient.setQueryData(
          ["smstemplates"],
          (cache: any[] | undefined) =>
            Array.isArray(cache)
              ? [...cache.filter(({ id }) => id !== template.id), template]
              : [template],
        ),
      onError: error,
    },
  );
}

export function useDeleteSMSTemplate() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(async (id: string) => deleteSMSTemplate(token, id), {
    onSuccess: (templateId) =>
      queryClient.setQueryData(
        ["smstemplates"],
        (cache: ISMSTemplateOut[] | undefined) =>
          Array.isArray(cache)
            ? cache.filter(({ id }) => id !== templateId)
            : cache,
      ),
    onError: error,
  });
}

// Scheduled SMS hooks
export function useScheduledSMSes() {
  const { token } = useAuthToken();
  const { error } = useError();
  return useQuery<IScheduledSMS[], undefined>(
    ["scheduledsmses"],
    async () => fetchScheduledSMSes(token),
    { onError: error },
  );
}

export function useAddScheduledSMS() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (sms: IScheduledSMSOut) => createScheduledSMS(token, sms),
    {
      onSuccess: (sms) =>
        queryClient.setQueryData(
          ["scheduledsmses"],
          (cache: IScheduledSMS[] | undefined) =>
            Array.isArray(cache) ? [...cache, sms] : cache,
        ),
      onError: error,
    },
  );
}

export function useUpdateScheduledSMS(id: string) {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (schedule: IScheduledSMSOut) => {
      return updateScheduledSMS(token, id, schedule);
    },
    {
      onSuccess: (sms) => {
        queryClient.setQueryData(
          ["scheduledsmses"],
          (cache: IScheduledSMS[] | undefined) => {
            return Array.isArray(cache)
              ? [...cache.filter(({ id }) => id !== sms.id), sms]
              : [sms];
          },
        );
      },
      onError: error,
    },
  );
}

export function useDeleteScheduledSMS() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(async (id: string) => deleteScheduledSMS(token, id), {
    onSuccess: (scheduleId) =>
      queryClient.setQueryData(
        ["scheduledsmses"],
        (cache: IScheduledSMS[] | undefined) =>
          Array.isArray(cache)
            ? cache.filter(({ id }) => id !== scheduleId)
            : cache,
      ),
    onError: error,
  });
}

export function useDeleteFormSMS() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(async (form: string) => deleteFormSMS(token, form), {
    onSuccess: (formId) =>
      queryClient.setQueryData(
        ["scheduledsmses"],
        (cache: IScheduledSMS[] | undefined) =>
          Array.isArray(cache)
            ? cache.filter(({ form }) => form !== formId)
            : cache,
      ),
    onError: error,
  });
}

// Deliverability
export function useDeliverability(patients?: IPatient[]) {
  const { token } = useAuthToken();
  const { error } = useError();
  const phones = patients ? patients.map((p) => `${p.phone}`) : [];

  return useQuery<IPhoneDeliveryStatus, undefined>(
    ["sms-deliverability"],
    async () => fetchDeliverability(token, phones),
    { onError: error },
  );
}
